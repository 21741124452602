/**
* @description 基础设置
**/
const extraSet = (route, req, store, context) => {
    // 国家切换保持登录状态
    const queryUse = () => {
        const query = route.query;
        query.userId && (context.$cookies.set('userId', query.userId))
        query.deviceId && (context.$cookies.set('deviceId', query.deviceId))
        query.token && (context.$cookies.set('token', query.token))
    };
    queryUse()
};

export default function ({route, req, redirect, store,res, ...context}) {
    const c = context.$cookies.getAll() || {}
    const query = route.query;
    const domain = req?.headers?.domain;
    const pageIp = req?.headers['X-Forwarded-For'] || req?.headers['x-forwarded-for'];
    const mainName = ['m.yfn.com', 'mmall.preview.selleroa.com'];
    const toType = Object.prototype.toString;
    // 协议
    const agreement = process.env.NODE_ENV == 'pre' ? 'http://' : 'https://';
    // 入参
    let params = {};
    if(query.country_code && toType.call(query.country_code) == '[object Array]') {
        const countryCodeLength = query.country_code.length;
        const lastCountryCode = query.country_code[countryCodeLength - 1] || '';
        lastCountryCode && (params = {countryCode: lastCountryCode})
    } else {
        params = query.country_code ? {countryCode: query.country_code} : {};
    }
    if(process.env.NODE_ENV != 'development' && domain) {
        params.origin = `${agreement}${domain}`
        params.getByIp = true
    }
    // nginx域名
    // domain && context.$cookies.set('page/domain', `${domain}`)
    // params.origin && context.$cookies.set('page/origin', params.origin)

    // nginx ip
    let headers = {};
    if(pageIp) {
        // context.$cookies.set('page/ip', `${pageIp}`)
        headers['X-Forwarded-For'] = pageIp
    }
    // 额外设置
    extraSet(route, req, store, context);
    headers['language'] = c?.language?.identify||'en_US';
    // 获取当前地区ip
    if(!query.country_code && c?.country?.id && c?.currency?.code && c?.language?.identify) return
    return context.$api.common.getCountryIp(params, headers).then(response => {
        let res = response.result || {};
        // if(res.mRealmName.includes('https://m.yfn.com')){
        //     res.currencyCode = 'USD'
        //     res.currencySymbol = '$'
        //     res.language = 'en_US'
        //     res.langCode = 'en'
        //     res.languageName = 'English'
        //     res.countryCode = 'US'
        //     res.englishName = 'United States'
        // }
        const ipInfo = response.ipResult || {};
        let country = res;
        // 重定向暂时关闭，cdn缓存可能会导致后续用户都进行重定向
        // if(mainName.includes(domain) && params.origin && country.mRealmName != params.origin && !query.country_code) {
        //     const redirectUrl = `${country.mRealmName}${route.fullPath}`;
        //     return redirect(redirectUrl)
        // }
        // 如果是m.yfn.com则使用美国站点信息
        
        // 缓存
        const currency = {
            code: res.currencyCode,
            symbol: res.currencySymbol
        };
        const language = {
            identify: res.language,
            langCode: res.langCode,
            language: res.languageName
        };
        // context.$cookies.set('country', country);
        // context.$cookies.set('currency', currency);
        // context.$cookies.set('language', language);
        // context.$cookies.set('page/ipName', ipInfo.mRealmName || '');
        // context.$cookies.set('page/countryParams', params || '');
        // 通用数据设置
        store.commit('national/setCountry', country)
        store.commit('national/setCurrency', currency)
        store.commit('national/setLanguage', language)
    })
}